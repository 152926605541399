.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.custom-fs {
  font-size: 1rem; /* Default font size */
}

@media (min-width: 576px) {
  .custom-fs {
    font-size: 1.25rem; /* Adjust font size for small screens */
  }
}

@media (min-width: 768px) {
  .custom-fs {
    font-size: 1.5rem; /* Adjust font size for medium screens */
  }
}

.bg-primary {
  background-color: #435F78 !important;
}

.bg-light {
  background-color: #E8E8E7 !important;
}

.nav-link {
  cursor: pointer;
}
