/* About.css */

  
  .custom-paragraph {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  
  .about-image {
    border: 5px solid #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  